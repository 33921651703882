import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import ProductFiltersContext from '@/context/ProductFiltersContext';

import Grid from '@/atoms/Grid';

import CategoryHeader from '@/components/CategoryHeader';
import ProductGrid from '@/components/ProductGrid';
import SchemaOrg from '@/components/SchemaOrg';
import Head from '@/components/Head';

export default ({ data, location }) => {
  const { wines } = data.graph;
  const [isClient, setClient] = useState(false);
  const [productFilters] = useContext(ProductFiltersContext);

  useEffect(() => {
    setClient(true);
  }, []);

  return (
    <>
      <Head title="Wines" canonical={location.pathname} />
      <SchemaOrg
        type="breadcrumbs"
        breadcrumbs={[
          {
            name: 'Wines',
            path: '/wines/',
          },
        ]}
      />

      <CategoryHeader title="Wines" />

      <Grid.Container as="section">
        <ProductGrid
          filters={isClient && productFilters}
          products={wines}
          tileSize="small"
          hideSoldOut
        />
      </Grid.Container>
    </>
  );
};

export const query = graphql`
  query {
    graph {
      wines(where: { status: PUBLISHED }, orderBy: sku_ASC) {
        ...WineData
      }
    }
  }
`;
